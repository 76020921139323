exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-data-privacy-js": () => import("./../../../src/pages/data-privacy.js" /* webpackChunkName: "component---src-pages-data-privacy-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presentation-js": () => import("./../../../src/pages/presentation.js" /* webpackChunkName: "component---src-pages-presentation-js" */),
  "component---src-slides-anzeigen-im-epaper-sind-relevant-js": () => import("./../../../src/slides/anzeigen-im-epaper-sind-relevant.js" /* webpackChunkName: "component---src-slides-anzeigen-im-epaper-sind-relevant-js" */),
  "component---src-slides-auch-epaper-werden-geteilt-js": () => import("./../../../src/slides/auch-epaper-werden-geteilt.js" /* webpackChunkName: "component---src-slides-auch-epaper-werden-geteilt-js" */),
  "component---src-slides-epaper-machen-anzeigen-mobil-js": () => import("./../../../src/slides/epaper-machen-anzeigen-mobil.js" /* webpackChunkName: "component---src-slides-epaper-machen-anzeigen-mobil-js" */),
  "component---src-slides-epaper-machen-mobil-js": () => import("./../../../src/slides/epaper-machen-mobil.js" /* webpackChunkName: "component---src-slides-epaper-machen-mobil-js" */),
  "component---src-slides-epaper-sind-eine-regionale-institution-js": () => import("./../../../src/slides/epaper-sind-eine-regionale-institution.js" /* webpackChunkName: "component---src-slides-epaper-sind-eine-regionale-institution-js" */),
  "component---src-slides-epaper-sind-jung-und-mobil-js": () => import("./../../../src/slides/epaper-sind-jung-und-mobil.js" /* webpackChunkName: "component---src-slides-epaper-sind-jung-und-mobil-js" */),
  "component---src-slides-epaper-sind-ueberall-gut-zu-nutzen-js": () => import("./../../../src/slides/epaper-sind-ueberall-gut-zu-nutzen.js" /* webpackChunkName: "component---src-slides-epaper-sind-ueberall-gut-zu-nutzen-js" */),
  "component---src-slides-epaper-werden-intensiv-genutzt-js": () => import("./../../../src/slides/epaper-werden-intensiv-genutzt.js" /* webpackChunkName: "component---src-slides-epaper-werden-intensiv-genutzt-js" */),
  "component---src-slides-fast-jeder-dritte-nutzt-epaper-js": () => import("./../../../src/slides/fast-jeder-dritte-nutzt-epaper.js" /* webpackChunkName: "component---src-slides-fast-jeder-dritte-nutzt-epaper-js" */),
  "component---src-slides-leser-nutzen-alle-teile-des-epaper-js": () => import("./../../../src/slides/leser-nutzen-alle-teile-des-epaper.js" /* webpackChunkName: "component---src-slides-leser-nutzen-alle-teile-des-epaper-js" */),
  "component---src-slides-nutzungsweisen-des-epaper-js": () => import("./../../../src/slides/nutzungsweisen-des-epaper.js" /* webpackChunkName: "component---src-slides-nutzungsweisen-des-epaper-js" */),
  "component---src-slides-start-js": () => import("./../../../src/slides/start.js" /* webpackChunkName: "component---src-slides-start-js" */)
}

